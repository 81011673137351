import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default Controller.extend({
  checkout: service(),
  currentUser: service(),
  store: service(),
  intl: service(),
  queryParams: ['code', 'order_ids'],
  code: '',
  order_ids: null,
  actions: {
    retry() {
      if (this.get('currentUser.groupOneWorkflow')) {
        const orderId = this.order_ids.split(',')[0];
        return this.store.findRecord('wy-order', orderId).then(order => {
          if (['canceled'].includes(this.code)) {
            // for canceled payment crete new order
            const orderModel = this.store.createRecord('wy-order', {
              basketHash: order.get('basketHash'),
              rurl: `${window.location.origin}/payment-check`,
              currency: this.get('currentUser.currency'),
              order,
              paylinks: false,
              test: false
            });


            return orderModel.save().then(order => {
              const redirectUrl = order.get('paymentLink');
              if (!redirectUrl) {
                // reset order items in cookies - order failed
                document.cookie = 'order_items=; path=/';
                throw new Error('Payment link missing');
              }
              window.location = redirectUrl;
              return;
            });
          } else {
            // for other states try pay again
            const redirectUrl = order.get('paymentLink');
            if (!redirectUrl) {
              // reset order items in cookies - order failed
              document.cookie = 'order_items=; path=/';
              throw new Error('Payment link missing');
            }
            window.location = redirectUrl;
            return;
          }
        });
      }

      const paymentMethod = this.store.findRecord(
        'paymentMethod',
        this.get('model.paymentMethodId')
      );

      this.payment(
        this.get('model.origInvoiceId'),
        this.get('model.paymentMethodId'),
        null,
        { channel: this.get('model.channel') }
      );
    },
    changePaymentMethod() {
      this.transitionToRoute('payment-method');
    }
  }
});
