import Service, { inject as service } from '@ember/service';

import config from '../config/environment';
import Logger from '../utils/logger';
import cookiesParser from '../utils/cookies-parser';

const cookies = cookiesParser();

export default Service.extend({
  cart: service(),
  store: service(),
  currentUser: service(),
  cartItems() {
    return (this.get('cart.items') || []).map(cartItem => cartItem.attrs());
  },
  removeLastSaved() {
    const lastBasketHash = cookies && cookies.basket_hash;
    Logger.log('[SERVER CART] Removing last saved basket', lastBasketHash);
    document.cookie = `basket_hash=; path=/`;
  },
  loadSaved() {
    const lastBasketHash = cookies && cookies.basket_hash;
    Logger.log('[SERVER CART] Loading saved basket', lastBasketHash);
    if (!lastBasketHash) return [];
    return this.store
      .findRecord('basket', lastBasketHash, { reload: true })
      .then(basket => {
        Logger.log('[SERVER CART] Loaded saved basket', basket.get('hash'));
        if (basket) {
          return basket.cartItems.map(cartItem => cartItem.parseAttrs());
        }
      });
  },

  storeNew() {
    Logger.log('[SERVER CART] Creating new basket');
    const basket = this.store.createRecord('basket', {
      currency: this.get('currentUser.currency'),
      key: 'current',
      lang: config.LOCALE,
      order: {
        coupon: this.cart?.coupon,
        customer: {},
        items: this.cartItems()
      }
    });
    return basket.save().then(basketData => {
      document.cookie = `basket_hash=${basketData.hash}; path=/`;
      return basketData;
    });
  },
  async load() {
    Logger.log('[SERVER CART] Loading basket');
    try {
      const basket = await this.store.findRecord('basket', 'current');
      return basket.cartItems.map(cartItem => cartItem.parseAttrs());
    } catch (error) {
      this.storeNew();
      throw error;
    }
  },
  async save() {
    Logger.log('[SERVER CART] Saving basket');
    const basket = this.store.peekAll('basket').findBy('key', 'current');

    if (!basket) {
      return this.storeNew();
    } else {
      basket.set('lang', config.LOCALE);
      basket.set('currency', this.get('currentUser.currency'));
      basket.set('order', {
        customer: {},
        coupon: this.cart?.coupon,
        items: this.cartItems()
      });
      return basket.save();
    }
  },
  async delete() {
    Logger.log('[SERVER CART] Deleting basket');
    const baskets = this.store.peekAll('basket').filterBy('key', 'current');
    this.removeLastSaved();
    return Promise.all(
      baskets.map(basketItem => {
        // destroy nejde, uz neni session
        // return basketItem.destroyRecord().then(() => {
        try {
          return basketItem.unloadRecord();
        } catch (error) {
          Logger.error('[SERVER CART] Error deleting basket', error);
        }
      })
    );
  }
});
