import WyApiAdapter from './wy-api';
import addDefaultParams from '../utils/default-wyapi-params';
import { inject as service } from '@ember/service';
import config from '../config/environment';

const addParams = (url, options) => {
  // currency, lang
  const params = { basket_info: true, lang: config.LOCALE, ...options };
  return addDefaultParams(url, params);
};

export default WyApiAdapter.extend({
  currentUser: service(),
  authorize(xhr) {
    if (!this.currentUser.groupOneWorkflow) {
      this._super(xhr);
    }
  },
  ensureResponseAuthorized(status, headers, payload /*, requestData */) {
    // nekonzistence v API, je potreba kontrolovat status message
    if (
      status === 400 &&
      payload &&
      payload.error &&
      payload.error.message &&
      payload.error.message.includes('logged')
    ) {
      this.get('session').invalidate();
    }

    return this._super(...arguments);
  },
  pathForType() {
    return 'order/basket';
  },
  urlForFindRecord(id, modelName, snapshot) {
    return this._super(id === 'current' ? null : id, modelName, snapshot);
  },
  urlForUpdateRecord(id, modelName, snapshot) {
    return this._super(snapshot.record.get('hash'), modelName, snapshot);
  },
  urlForDeleteRecord(id, modelName, snapshot) {
    return this._super(snapshot.record.get('hash'), modelName, snapshot);
  },
  buildURL() {
    const url = this._super(...arguments);
    return addParams(url, { currency: this.currentUser.currency });
  }
});
