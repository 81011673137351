import DS from 'ember-data';
import WyApiAdapter from './wy-api';
import SingletonModelAdapterMixin from '../mixins/singleton-model-adapter';
import { inject as service } from '@ember/service';

const ERROR_CODE_MAP = [
  {
    code: 0,
    pointer: '/data/attributes/contact_email',
    messageKey: 'too_many_requests'
  },
  {
    code: 1350,
    pointer: '/data/attributes/contact_email',
    messageKey: 'already_registered'
  },
  {
    code: 1233,
    pointer: '/data/attributes/contact_phone',
    messageKey: 'invalid_phone'
  }
];

export default WyApiAdapter.extend(SingletonModelAdapterMixin, {
  intl: service(),
  currentUser: service(),
  namespace: 'auth',
  urlForCreateRecord() {
    if (this.get('currentUser.groupOneWorkflow')) {
      return `${this.groupOneHost}/v1/customers`;
    }
    return `${this.host}/order/create-user`;
  },
  urlForUpdateRecord(id) {
    // not implemented
    if (this.get('currentUser.groupOneWorkflow')) {
      return `${this.groupOneHost}/v1/customers/${id}`;
    }
    return `${this.host}/order/update-user`;
  },
  handleResponse() {
    const data = this._super(...arguments);
    if (data instanceof Error) {
      const { errors = [] } = data;

      if (!errors.any(item => item.source?.pointer)) {
        return new DS.InvalidError(
          errors.map(error => {
            const { detail = {} } = error;
            const errorItem = ERROR_CODE_MAP.findBy('code', detail.code);
            return {
              status: '422',
              source: { pointer: errorItem?.pointer },
              title: detail.message,
              code: detail.code,
              detail: detail.message
            };
          })
        );
      }
    }
    return data;
  }
});
