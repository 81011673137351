import WyApiSerializer from './wy-api';
import { inject as service } from '@ember/service';

export default WyApiSerializer.extend({
  currentUser: service(),
  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    if (requestType === 'createRecord') {
      payload = { data: payload };
    }
    return this._super(store, primaryModelClass, payload, id, requestType);
  },
  serialize: function (snapshot, options) {
    if (this.currentUser.groupOneWorkflow) {
      const json = this._super(snapshot, options);

      return {
        customer: {
          company: json.legal_form === 'SRO',
          name: json.contact_name,
          company_name: json.invoice_name,
          street: json.invoice_street,
          city: json.invoice_town,
          country_code: json.invoice_state,
          postal_code: json.invoice_postcode,
          company_number: json.invoice_companyid,
          vat_number: json.invoice_tax,
          phone: json.contact_phone.replace('+', '').split('.'),
          email: json.contact_email,
          accepted_terms_at: new Date().toISOString(),
          accepted_marketing_emails: true
        }
      };
    }
    return this._super(snapshot, options);
  }
});
