import { cancel, later, scheduleOnce } from '@ember/runloop';

import $ from 'jquery';
import Common from '../mixins/common';
import Component from '@ember/component';
import { computed } from '@ember/object';
import config from '../config/environment';
import { alias, filterBy } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { tryInvoke } from '@ember/utils';

export default Component.extend(Common, {
  intl: service(),
  modal: service(),
  store: service(),
  currentUser: service(),
  classNames: ['contact-directory'],
  modalHasImport: false,
  selected: null,
  contacts: null,
  contactsFiltered: filterBy('contacts', 'isNew', false),
  contactsPage: 1,
  contactsOffset: 0,
  contactsPaginate: computed(
    'contactsFiltered.[]',
    'contactsPage',
    'contactsOffset',
    function () {
      return this.get('contactsFiltered').slice(
        0,
        this.get('contactsPage') * this.get('contactsOffset')
      );
    }
  ),
  contactsLoading: false,
  contact: null,
  newContact: null,
  contactAttrsInfo: null,
  importContact: null,
  countries: null,
  domains: null,
  withSearch: false,
  forceExpand: false,
  groupOneWorkflow: alias('currentUser.groupOneWorkflow'),
  searchValue: '',
  searchLimit: 3,
  scrollHandler: null,
  displayRow: false,
  lastCreatedContact: null,
  searchHelperFocus: false,
  type: 'owner', //owner/invoice
  searchHelperOpen: computed('searchValue', 'searchHelperFocus', function () {
    if (
      this.get('searchValue.length') < this.get('searchLimit') &&
      this.get('searchHelperFocus')
    ) {
      document.querySelector(
        '.contact-directory-with-search .contact-directory-panel'
      ).style.marginBottom = '160px';
      if (this.get('deviceDetection.isDesktop')) {
        scheduleOnce('afterRender', () => {
          window.scrollTo(0, document.body.scrollHeight);
        });
      } else {
        // bud spusti timer nebo resize
        // timer protoze safari nevola resize pri zobrazeni klavesnice,
        let timer = later(this._scrollKeyboard, 500);
        $(window).one('resize', () => {
          scheduleOnce('afterRender', () => {
            cancel(timer);
            this._scrollKeyboard(true);
          });
        });
      }
      return true;
    }

    if (this.get('deviceDetection.isDesktop')) {
      document.querySelector('.contact-directory-panel').style.marginBottom =
        '25px';
    }
    return false;
  }),
  contactsSearched: computed('searchValue', 'contactsFiltered.[]', function () {
    if (this.get('searchValue.length') >= this.get('searchLimit')) {
      return this.get('contactsFiltered').filter(contact => {
        let searchValue = this.stripAccent(
          this.get('searchValue').toLowerCase()
        );
        let name = contact.get('name') || '';
        let email = contact.get('email') || '';
        let companyName = contact.get('companyName') || '';
        let ic = contact.get('ic') || '';
        let dic = contact.get('dic') || '';
        let phone = contact.get('phone') || '';
        let street = contact.get('street') || '';
        let city = contact.get('city') || '';
        let rrIds = contact.get('rrIds') || '';

        name = name ? this.stripAccent(name) : name;
        companyName = companyName ? this.stripAccent(companyName) : companyName;
        street = street ? this.stripAccent(street) : street;
        city = city ? this.stripAccent(city) : city;

        let nameCheck = name.toLowerCase().indexOf(searchValue) !== -1;
        let emailCheck = email.toLowerCase().indexOf(searchValue) !== -1;
        let companyNameCheck =
          companyName.toLowerCase().indexOf(searchValue) !== -1;
        let icCheck = ic.toLowerCase().indexOf(searchValue) !== -1;
        let dicCheck = dic.toLowerCase().indexOf(searchValue) !== -1;
        let phoneCheck = phone.toLowerCase().indexOf(searchValue) !== -1;
        let streetCheck = street.toLowerCase().indexOf(searchValue) !== -1;
        let cityCheck = city.toLowerCase().indexOf(searchValue) !== -1;
        let rrIdsCheck;

        function rrIdSearch(rrIds) {
          let found = false;

          rrIds.forEach(rrId => {
            if (rrId.toLowerCase().indexOf(searchValue) !== -1) {
              found = true;
            }
          });

          return found;
        }

        if (rrIds) {
          for (let i in rrIds) {
            rrIdsCheck = rrIdSearch(rrIds[i]);
            if (rrIdsCheck) {
              break;
            }
          }
        }

        scheduleOnce('afterRender', () => {
          contact.setProperties({
            nameFound: nameCheck,
            emailFound: emailCheck,
            companyNameFound: companyNameCheck,
            icFound: icCheck,
            dicFound: dicCheck,
            phoneFound: phoneCheck,
            streetFound: streetCheck,
            cityFound: cityCheck,
            rrIdsFound: rrIdsCheck
          });
        });

        return (
          nameCheck ||
          emailCheck ||
          companyNameCheck ||
          icCheck ||
          dicCheck ||
          phoneCheck ||
          streetCheck ||
          cityCheck ||
          rrIdsCheck
        );
      });
    }
  }),
  init() {
    this._super(...arguments);

    if (this.get('withSearch')) {
      this.set('contactsOffset', Math.ceil($(window).height() / 80) * 3);

      scheduleOnce('afterRender', () => {
        this.set('scrollHandler', () => {
          if (
            $(window).scrollTop() + $(window).height() >=
            $('body').height() - 10
          ) {
            if (
              this.get('contactsPaginate.length') <
              this.get('contactsFiltered.length')
            ) {
              this.incrementProperty('contactsPage');
              this.set('contactsLoading', true);
            } else {
              this.set('contactsLoading', false);
            }
          }
        });

        $(window).scroll(this.get('scrollHandler'));
      });
    }
  },
  didInsertElement() {
    scheduleOnce('afterRender', () => {
      let selected = this.get('selected');
      if (selected) {
        tryInvoke(this, 'contactSelectedUnshift', [selected]);
      }
    });
  },
  didDestroyElement() {
    if (this.get('withSearch')) {
      $(window).off('scroll', this.get('scrollHandler'));
    }
  },
  actions: {
    modalOpen(hasImport, contact) {
      const defaultDialingCode = `+${config.DEFAULT_DIALING_CODE}` || '+420';
      const defaultCountryId = config.DEFAULT_COUNTRY_ID || 203;
      let properties = {
        dialingCode: defaultDialingCode,
        countryId: defaultCountryId
      };

      if (contact) {
        properties = contact.getProperties([
          'name',
          'email',
          'company',
          'ic',
          'dic',
          'dialingCode',
          'phone',
          'companyName',
          'city',
          'zip',
          'street',
          'countryId'
        ]);

        if (properties.dialingCode === null) {
          properties.dialingCode = defaultDialingCode;
        }
        if (properties.countryId === null) {
          properties.countryId = defaultCountryId;
        }
      }

      this.set('contactAttrsInfo', []);

      for (let i in properties) {
        if (properties[i] === null) {
          properties[i] = '';

          if (i === 'phone' || i === 'email' || i === 'street') {
            this.get('contactAttrsInfo').push(i);
          }
        }
      }

      this.set(
        'newContact',
        this.get('store').createRecord(
          this.type === 'owner' ? 'contact' : 'billingProfile',
          properties
        )
      );
      this.get('modal').show('forms/contact-select-full', null, {
        btnNewText: contact
          ? this.intl.t('contact_directory.new_added_contact')
          : this.intl.t('contact_directory.save_contact'),
        closeAction: () => {
          this.send('modalClose');
        },
        editContact: this.get('newContact'),
        header: this.intl.t(
          hasImport
            ? 'contact_directory.new_contact_title1'
            : 'contact_directory.new_contact_title2'
        ),
        onSelect: result => {
          this.set('contact', result);
          this.set('lastCreatedContact', result);
        },
        type: 'domena',
        showTabs: `new${hasImport ? ',import' : ''}`
      });
      this.send('modalOpened');
    },
    modalClose() {
      let contact = this.get('newContact');
      if (contact) {
        contact.rollbackAttributes();
      }

      contact = this.get('importContact');
      if (contact) {
        contact.rollbackAttributes();
      }
    },
    modalOpened() {
      // po nastaveni dat zavolat validace
      scheduleOnce('afterRender', function () {
        $('.contact-form :input').each((index, input) => {
          input = $(input);
          if (input.val() !== '') {
            input.trigger('focusout');
          }
        });
      });
    }
  },
  _scrollKeyboard() {
    let input = $('input:focus');
    let navbar = $('.top-page-stack');
    let scrollTop = input.offset().top - input.height(); // safari nezobrazi menu pri zobrazeni klavesnice
    if (navbar.position().top === 0) {
      scrollTop -= navbar.height();
    }
    window.scrollTo(0, scrollTop);
  }
});
