import { computed, trySet } from '@ember/object';

import DS from 'ember-data';
import Logger from '../utils/logger';
import Order from './order';
import config from '../config/environment';
import { inject as service } from '@ember/service';

export default Order.extend({
  currentUser: service(),
  key: DS.attr('string'),
  coupon: DS.attr('string'),
  channel: DS.attr('string'),
  ownerContactId: DS.attr('number'),
  invoiceContactId: DS.attr('number'),
  adminContactId: DS.attr('number'),
  // TODO-FIX proc tu neni taky ID
  paymentMethod: DS.attr('number'),
  identityCardNumber: DS.attr('string'), // DS.attrPerson -> 'identity_card'
  vatNumber: DS.attr('string'), // DS.attrPerson -> 'dic'
  xxxId: DS.attr('string'), // DS.attrPerson -> xxx_id
  xxxActive: DS.attr('boolean'), // DS.attrPerson -> xxx_id
  docUrl: DS.attr('string'), // required doc url
  mailHostingEmail: DS.attr('string'),
  mailHostingDialingCode: DS.attr('string', {
    defaultValue: `+${config.DEFAULT_DIALING_CODE}` || '+420'
  }),
  mailHostingPhone: DS.attr('string'),
  mailHostingName: DS.attr('string'),
  autoRenew: DS.attr('boolean', { defaultValue: true }),
  registrationRulesConsent: DS.attr('boolean', { defaultValue: false }),
  nssetId: DS.attr('string'),
  zoneId: DS.attr('string'),
  zoneType: DS.attr('string'),
  httpsCheck: DS.attr('boolean'),
  createdAt: DS.attr('date', {
    defaultValue() {
      return new Date();
    }
  }),
  reset() {
    Logger.debug('Resetting checkout');
    this.setProperties({
      channel: null,
      invoiceContactId: null,
      adminContactId: null,
      paymentMethod: null,
      paymentMethodObject: null,
      mailHostingEmail: null,
      mailHostingName: null,
      mailHostingDialingCode: '+420',
      mailHostingPhone: null,
      registrationRulesConsent: false,
      autoRenew: true,
      nssetId: null,
      zoneId: null,
      zoneType: null
    });
    this.resetPartial();
    return this.save();
  },
  resetPartial() {
    this.set('ownerContactId', null);
    this.resetSpecials();
  },
  resetSpecials() {
    this.setProperties({
      identityCardNumber: null,
      vatNumber: null,
      xxxId: null,
      xxxActive: null,
      docUrl: null,
      httpsCheck: false
    });
  },
  ownerContact: computed('ownerContactId', 'currentUser.loadedData.contacts', {
    get() {
      if (
        this.get('ownerContactId') &&
        this.get('currentUser.loadedData.contacts')
      ) {
        return this.store.peekRecord('contact', this.get('ownerContactId'));
      }
      return null;
    },
    set(key, value) {
      if (typeof value === 'object') {
        this.set('ownerContactId', value ? value.get('id') : null);
        this.save();
      }
      return value;
    }
  }),
  invoiceContact: computed(
    'invoiceContactId',
    'currentUser.loadedData.invoiceContacts',
    {
      get() {
        if (
          this.get('invoiceContactId') &&
          this.get('currentUser.loadedData.invoiceContacts')
        ) {
          return this.store.peekRecord(
            'billingProfile',
            this.get('invoiceContactId')
          );
        }
        return null;
      },
      set(key, value) {
        if (typeof value === 'object') {
          this.set('invoiceContactId', value ? value.get('id') : null);
          this.save();
        }
        return value;
      }
    }
  ),
  adminContact: computed('adminContactId', 'currentUser.loadedData.contacts', {
    get() {
      if (this.get('adminContactId')) {
        return this.store.peekRecord('contact', this.get('adminContactId'));
      }
    },
    set(key, value) {
      trySet(this, 'adminContactId', value ? value.get('id') : null);
      this.save();

      return value;
    }
  }),
  paymentMethodObject: computed('paymentMethod', 'model.paymentMethods.[]', {
    get() {
      if (this.get('paymentMethod')) {
        return this.store.peekRecord(
          'paymentMethod',
          this.get('paymentMethod')
        );
      }
    },
    set(key, value) {
      trySet(this, 'paymentMethod', value ? value.get('id') : null);
      this.save();

      return value;
    }
  }),
  selectedChannel: computed('channel', {
    get() {
      return this.get('channel');
    },
    set(key, value) {
      this.set('channel', value);
      this.save();

      return value;
    }
  }),
  // WY-API attrs
  invoice: DS.attr()
});
