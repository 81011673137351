import Service, { inject as service } from '@ember/service';

import SERVICE_CONFIG from '../utils/service-config';
import config from '../config/environment';
import fetchAjax from '../utils/ajax';
import { filterBy } from '@ember/object/computed';
import moment from 'moment';
import { reject } from 'rsvp';

export default Service.extend({
  currentUser: service(),
  fastboot: service(),
  store: service(),
  featuresSort: null,
  init() {
    this._super(...arguments);
    this.set(
      'featuresSort',
      config.LOCALE === 'cs'
        ? ['cz', 'eu', 'com', 'de', 'sk', 'xyz']
        : ['sk', 'eu', 'cz', 'online', 'com', 'space', 'store', 'tech', 'net']
    );
  },
  _loadTlds() {
    const path = this.get('fastboot.isFastBoot')
      ? `${this.get('fastboot.request.protocol')}//${this.get(
          'fastboot.request.host'
        )}/`
      : '/';
    return this.store.findAll('tld', { reload: true }).then(tlds => {
      return fetchAjax(`${path}tldCategories-${config.LOCALE}.json`, {
        cache: 'no-cache'
      }).then(categories => {
        tlds.forEach(tld => {
          tld.setProperties({
            categories: categories[tld.id] ? categories[tld.id].categories : [],
            defaultFeatured: categories[tld.id]
              ? categories[tld.id].defaultFeatured
              : false
          });
        });
        return tlds;
      }, reject);
    }, reject);
  },
  _createDomainCheckList() {
    const domains_data = [];
    const offersData = this.store.peekAll('offer');
    const tlds = this.store.peekAll('tld');
    const tldsCount = tlds.get('length');

    tlds.forEach((tld, index) => {
      let position = this.get('featuresSort').indexOf(tld.get('name'));
      let domainOffers = offersData
        .filter(item => {
          let from = item.get('from'),
            until = item.get('until'),
            nowTimestamp = new Date().getTime(),
            fromDateTimestamp,
            untilDateTimestamp;

          if (from) {
            fromDateTimestamp = moment(from).startOf('day').valueOf();
          }

          if (until) {
            untilDateTimestamp = moment(until).endOf('day').valueOf();
          }

          if (!from || fromDateTimestamp <= nowTimestamp) {
            if (!until || nowTimestamp <= untilDateTimestamp) {
              return true;
            }
          }

          return false;
        })
        .filterBy('tld', tld.get('name'));

      tld.set('offers', domainOffers);
      tld.set('currentState.isDirty', false); // reset isDirty state

      domains_data.push({
        id: index + 1,
        type: 'domain-check',
        relationships: { tld: { data: { type: 'tld', id: tld.get('id') } } },
        attributes: {
          category: tld.get('category'),
          // pokud je offer pozice na konci, jinak pozice podle featuresSort a kdyz neni tak nekde do stredu
          position: tld.get('activeOffer')
            ? tldsCount + 100 + (tld.get('activeOffer.position') || 0)
            : position === -1
            ? tldsCount + 10
            : position
        }
      });
    });

    return this.store.push({ data: domains_data });
  },
  _createPrices() {
    const tlds = this.store.peekAll('tld');

    const tldPrices = tlds.map(tld => {
      let periods = tld.get('supportedMonths');
      const originalPrice = tld.get('price');
      const customerDiscount = tld.get('customerDiscount');
      const price = tld.get('customerPrice');
      const transferPrice = tld.get('renewCustomerPrice');
      if (!Array.isArray(periods)) {
        periods = Object.values(periods);
      }

      const minimalPeriod = Math.min(...periods);
      const pricePerMinimalPeriod = price / (minimalPeriod / 12);
      const registration = periods.reduce((out, item) => {
        const multiplier = item / 12;
        out[multiplier] = pricePerMinimalPeriod * multiplier;
        return out;
      }, {});
      const transfer = { 1: transferPrice };
      return {
        attributes: {
          customerDiscount,
          originalPrice,
          registration,
          transfer,
          tld: tld.id
        },
        id: tld.id,
        type: 'price'
      };
    });

    return this.store.push({ data: tldPrices });
  },
  _updatePrices() {
    const tlds = this.store.peekAll('tld');
    const prices = this.store.peekAll('price');
    tlds.forEach(tld => {
      let periods = tld.get('supportedMonths');
      const price = tld.get('customerPrice');
      if (!Array.isArray(periods)) {
        periods = Object.values(periods);
      }

      const minimalPeriod = Math.min(...periods);
      const pricePerMinimalPeriod = price / (minimalPeriod / 12);
      const registration = (periods || []).reduce((out, item) => {
        const multiplier = item / 12;
        out[multiplier] = pricePerMinimalPeriod * multiplier;
        return out;
      }, {});
      const priceObject = prices.findBy('id', tld.get('id'));
      priceObject && priceObject.set('registration', registration);
    });

    this.store.peekAll('domain-check').forEach(domain => {
      let price = prices.findBy('id', domain.get('tldName'));
      domain.set('price', price);
    });
    return prices;
  },
  _createOrUpdateRecord(modelString, itemData) {
    if (this.store.hasRecordForId(modelString, itemData.id)) {
      const existingRecord = this.store.peekRecord(modelString, itemData.id);
      return existingRecord.setProperties(itemData);
    } else {
      return this.store.createRecord(modelString, itemData);
    }
  },
  _loadServiceList() {
    this.set('currentUser.loadedData.prices', false);
    const serviceConfig = SERVICE_CONFIG[config.LOCALE] || {};
    return this.store
      .queryRecord('formSettings', {
        packs: Object.keys(serviceConfig).filter(
          key => !serviceConfig[key].excludePack
        )
      })
      .then(data => {
        this.set('services', data.get('services'));
        const services = data.get('services').map(item => {
          const basePrices = (item.supportedPeriods || []).reduce(
            (out, period) => {
              out[period] = parseInt(period, 10) * parseFloat(item.basePrice);
              return out;
            },
            {}
          );
          const customerPrices = (item.supportedPeriods || []).reduce(
            (out, period) => {
              out[period] =
                parseInt(period, 10) * parseFloat(item.customerPrice);
              return out;
            },
            {}
          );

          const confirmationMailLocalParts = (
            item.packParams.findBy('param_name', 'OP_CONFIRMATION_MAIL_LP') || {
              values: []
            }
          ).values.mapBy('value');

          const baseParams = {
            basePrice: item.basePrice,
            customerDiscount: item.customerDiscount,
            customerPrice: item.customerPrice,
            description: item.description,
            id: item.id,
            title: item.title
          };

          const domainCountObject =
            (item.packParams || []).findBy('param_name', 'DOMAINS') || {};

          const domainsCount = (domainCountObject.values || [])
            .mapBy('value')
            .sort((a, b) => parseInt(a, 10) - parseInt(b, 10));

          const diskSize = (item.packParams || []).findBy('param_name', 'DISK');

          const userCount = (item.packParams || []).findBy(
            'param_name',
            'COUNT'
          );

          item.category = serviceConfig[item.pack].category;

          switch (item.category) {
            case 'mailhosting':
              return (item.supportedPeriods || []).reduce((out, period) => {
                let maxUsers = 10;

                if (userCount) {
                  const { value } = (userCount.values || [])[0] || {};
                  if (value) {
                    maxUsers = value.split('-')[1];
                  }
                }

                const diskSizes = diskSize && diskSize.values;
                if (diskSizes) {
                  diskSizes
                    .sort(
                      (a, b) => parseInt(a.value, 10) - parseInt(b.value, 10)
                    )
                    .forEach(size => {
                      const itemData = {
                        ...item,
                        ...baseParams,
                        customerPrice: parseFloat(size.price_pattern),
                        domainServiceType: item.id,
                        id: `${item.id}${size.value}.${period}`,
                        capacity: size.value || null,
                        pricePer: serviceConfig[item.pack].pricePer,
                        period,
                        customerContact:
                          serviceConfig[item.pack].customerContact
                      };
                      out.push(
                        this._createOrUpdateRecord('mailHostingPlan', itemData)
                      );
                    });
                } else {
                  const itemData = {
                    ...item,
                    ...baseParams,
                    domainServiceType: item.id,
                    id: `${item.id}.${period}`,
                    capacity: item.capacity || null,
                    maxUsers,
                    pricePer: serviceConfig[item.pack].pricePer,
                    period,
                    customerContact: serviceConfig[item.pack].customerContact
                  };

                  out.push(
                    this._createOrUpdateRecord('mailHostingPlan', itemData)
                  );
                }
                return out;
              }, []);
            case 'ssl':
              return this._createOrUpdateRecord('sslCertType', {
                ...item,
                ...baseParams,
                confirmationMailLocalParts,
                customerPrices,
                enabled: true,
                prices: basePrices
              });
            case 'hosting':
              return this._createOrUpdateRecord('webHostingPlan', {
                ...item,
                ...baseParams,
                basePrices,
                customerPrices,
                domainsCount
              });
            case 'wp_hosting':
              return this._createOrUpdateRecord('wordpressHostingPlan', {
                ...item,
                ...baseParams,
                basePrices,
                customerPrices
              });
            case 'yola':
              return this._createOrUpdateRecord('yolaPlan', {
                ...item,
                ...baseParams,
                basePrices,
                customerPrices
              });
          }
        });
        this.set('currentUser.loadedData.prices', true);
        return services;
      });
  },
  hostings: filterBy('services', 'category', 'hosting'),
  mailhostings: filterBy('services', 'category', 'mailhosting')
});
