/* eslint-disable max-lines */
import { assign } from '@ember/polyfills';
import { isPresent } from '@ember/utils';
import { hash, Promise as EmberPromise, all } from 'rsvp';
import { computed, observer } from '@ember/object';
import { allSettled } from 'rsvp';
import config from 'domena-mobile/config/environment';
import Logger from 'domena-mobile/utils/logger';
import fetchAjax from '../utils/ajax';
import Service, { inject as service } from '@ember/service';
import moment from 'moment';

const DEFAULT_PER_PAGE = 15;
const LIST_ORDER = ['expiring', 'active', 'dead'];
const REV_LIST_ORDER = ['active', 'expiring', 'dead'];
const EXTRA_LIST_TOTAL = ['waitingInvoice'];
const METADATA = {
  total: '...',
  totals: { expiring: '...', dead: '...', active: '...', waitingInvoice: '...' }
};
const FILTERED_METADATA = {
  total: '...',
  totals: { expiring: '...', dead: '...', active: '...', waitingInvoice: '...' }
}; // data pro aktualni filtr
const SORT_MODES = [
  { type: 'name', domains: 'all' },
  { type: 'expiresOn', domains: 'filtered' }
];

export default Service.extend({
  store: service(),
  currentUser: service(),
  routing: service(),
  session: service(),
  fastboot: service(),
  metadata: METADATA,
  filteredMetadata: FILTERED_METADATA, // data pro aktualni filtr
  activeMetadata: computed(
    'metadata.total',
    'filteredMetadata.total',
    'filterName',
    {
      get() {
        return this.get(
          this.get('filterName').length > 0 ? 'filteredMetadata' : 'metadata'
        );
      },
      set(key, value) {
        this.set(
          this.get('filterName').length > 0 ? 'filteredMetadata' : 'metadata',
          value
        );
        return value;
      }
    }
  ),
  page: 1,
  sortBy: SORT_MODES[1],
  sortDirection: 'asc',
  sortNames: computed(function () {
    return SORT_MODES.mapBy('type');
  }),
  filterName: '',
  filterNameWas: '',
  filterChanged: true,
  domainServices: null,
  sites: null,
  dasToken: null,
  domains: null,
  perPage: DEFAULT_PER_PAGE,
  pages: computed('activeMetadata.total', 'perPage', function () {
    return Math.ceil(this.get('activeMetadata.total') / this.perPage);
  }),
  selectedDomains: null,
  isLoading: false,
  dataObserver: observer(
    'page',
    'perPage',
    'filterName',
    'sites.[]',
    'sortBy',
    'sortDirection',
    function () {
      if (!this.session.isAuthenticated) {
        return;
      }

      const filterName = this.get('filterName');
      this.set('filterChanged', false);
      if (this.get('filterNameWas') !== filterName) {
        this.set('page', 1);
        this.set('filterChanged', true);
      }
      this.set('filterNameWas', filterName);
      this.loadData(this.get('forceReload'));
    }
  ),
  forceReloadData() {
    this.set('isLoading', true);
    this.set('forceReload', true);
    return this.loadMeta(true).then(
      () => {
        this.set('isLoading', false);
        this.set('forceReload', false);
      },
      error => {
        Logger.error(error);
        this.get('routing.transitionTo')('error', { error });
      }
    );
  },
  sortChange(type, direction) {
    this.setProperties({
      sortBy: SORT_MODES.findBy('type', type),
      sortDirection: direction
    });
  },
  init() {
    this._super(...arguments);
    this.set('selectedDomains', []);
  },
  reset() {
    Logger.debug('Resetting domain-list');
    this.setProperties({
      selectedDomains: [],
      sites: [],
      domainServices: [],
      domains: [],
      filterName: '',
      filterNameWas: ''
    });
  },
  loadMeta(forceReload) {
    if (this.get('domains') === null || forceReload) {
      const dataPromises = [this.getTotals()];

      dataPromises.pushObject(
        this.get('store')
          .findAll('site')
          .then(
            sites => {
              // tohle by chtelo resit nejak pomoci session, obcas neni auth hlavicka
              this.set('sites', sites);
              return sites;
            },
            e => {
              Logger.error(e);
            }
          )
      );

      return all(dataPromises);
    }
  },
  loadData(forceReload) {
    this.set('isLoading', true);
    const sites = this.get('sites') || [];
    return this.queryDomains(this.get('filterName')).then(
      domains => {
        if (isPresent(domains)) {
          this.get('store')
            .query('domainService', {
              filter: { domain_names: domains.mapBy('name').join(',') }
            })
            .then(
              domainServices => {
                this.set('domainServices', domainServices);
                domains.forEach(domain => {
                  domain.set(
                    'domainServices',
                    domainServices.filterBy('domainName', domain.get('name'))
                  );
                  domain.set('site', sites.findBy('id', domain.get('name')));
                });
              },
              error => {
                Logger.error(error);
                this.get('routing.transitionTo')('error', { error });
              }
            );
        }

        domains.forEach(domain => {
          domain.set('site', sites.findBy('id', domain.get('name')));
        });
        this.set('domains', domains);
        this.set('isLoading', false);
        return domains;
      },
      error => {
        Logger.error(error);
        this.get('routing.transitionTo')('error', { error });
        if (!forceReload) {
          this.set('isLoading', false);
        }
      }
    );
  },
  inSelectedDomains(domain) {
    return this.get('selectedDomains').includes(domain);
  },
  addDomain(domain) {
    if (!this.inSelectedDomains(domain)) {
      this.get('selectedDomains').pushObject(domain);
    }
  },
  removeDomain(domain) {
    this.get('selectedDomains').removeObject(domain);
  },
  removeAllDomains() {
    this.get('selectedDomains').clear();
  },
  deleteSelectedDomains() {
    return allSettled(
      this.get('selectedDomains').map(domain => {
        return domain.destroyRecord().then(destroyedDomain => {
          return destroyedDomain.unloadRecord();
        });
      })
    ).then(domains => {
      this.set('selectedDomains', []);
      this.set('page', 1);
      return domains;
    });
  },
  toggleDomain(domain) {
    if (this.inSelectedDomains(domain)) {
      this.removeDomain(domain);
    } else {
      this.addDomain(domain);
    }
  },
  queryDomains(filterName) {
    return this._makeRequest(filterName);
  },
  getTotals() {
    return this._getTotals(this.get('filterName')).then(data => {
      this.set('activeMetadata', data);
      this.notifyPropertyChange('page');
      return data;
    });
  },
  getDasToken() {
    this.set('dasToken', 'true');

    // skip check for now
    if (1 === 1) return;

    if (config.environment === 'development') {
      this.set('dasToken', 'true');
      return;
    }

    const path = this.get('fastboot.isFastBoot')
      ? `${this.get('fastboot.request.protocol')}//${this.get(
          'fastboot.request.host'
        )}/`
      : '/';

    fetchAjax(`${path}das-token`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }).then(
      results => {
        const dasToken = results.data.token;
        this.set('dasToken', dasToken);
      },
      error => {
        Logger.error(error);
        this.set('dasToken', false);
      }
    );
  },
  _getTotals(filterName) {
    const requestHash = {};
    const totals = {};
    let params = {};
    let total = 0;

    const sortType = this.sortBy.domains;
    const domainListNames =
      sortType === 'all'
        ? ['all']
        : (this.sortDirection === 'asc' ? LIST_ORDER : REV_LIST_ORDER).concat(
            EXTRA_LIST_TOTAL
          );

    domainListNames.forEach(key => {
      params = { filter: this._getFilter(key).filter };
      if (filterName) {
        params.filter['name'] = filterName;
      }
      params['page'] = { limit: 0, offset: 0 };
      requestHash[key] = this.get('store').query('domain', params);
    });

    return hash(requestHash).then(data => {
      domainListNames.forEach(key => {
        totals[key] = data[key].get('meta.total-count');

        // skip extra domain requests counts
        if (!EXTRA_LIST_TOTAL.includes(key)) {
          total += data[key].get('meta.total-count');
        }
      });
      totals['all'] = total;
      return { totals, total };
    });
  },
  _loadData(filterName) {
    const requestHash = {};
    const totals = this.get('activeMetadata').totals;
    const page = this.get('page');
    const limit = this.perPage;
    const offset = (page - 1) * this.perPage;
    let outArr = [];

    const sortType = this.sortBy.domains;
    const domainListNames =
      sortType === 'all'
        ? ['all']
        : this.sortDirection === 'asc'
        ? LIST_ORDER
        : REV_LIST_ORDER;

    // TODO mozna optimalizace, vytvaret jen cast pole

    domainListNames.forEach(key => {
      if (outArr.length < page * limit) {
        const typeTotal = totals[key];
        for (let i = 0; i < typeTotal; i++) {
          outArr.push({ type: key, index: i });
          if (outArr.length >= page * limit) {
            break;
          }
        }
      }
    });

    outArr = outArr.slice(offset, offset + limit);

    domainListNames.forEach(key => {
      const data = outArr.filterBy('type', key);
      if (isPresent(data)) {
        requestHash[key] = this._loadType(
          key,
          filterName,
          data.get('firstObject').index,
          data.get('lastObject').index - data.get('firstObject').index + 1
        );
      }
    });

    const out = [];
    return hash(requestHash).then(data => {
      domainListNames.forEach(key => {
        if (data[key]) {
          out.pushObjects(data[key]);
        }
      });

      return out;
    });
  },
  _loadType(key, filterName, offset, limit) {
    const params = assign(this._getFilter(key), { page: { offset, limit } });
    if (filterName) {
      params.filter['name'] = filterName;
    }
    return this.get('store')
      .query('domain', params, { reload: true })
      .then(domains => {
        domains = domains.toArray();
        domains.invoke('set', 'queryType', key);
        return domains;
      });
  },
  _makeRequest(filterName) {
    return new EmberPromise((resolve, reject) => {
      if (this.get('filterChanged')) {
        return this._getTotals(filterName).then(data => {
          this.set('filteredMetadata', data);
          return this._loadData(filterName).then(resolve, reject);
        }, reject);
      } else {
        return this._loadData(filterName).then(resolve, reject);
      }
    });
  },
  _getFilter(filterType) {
    const filter = {};
    let include = ['unpaid_orders'];
    let sort;
    switch (filterType) {
      case 'dead':
        filter['dead'] = true;
        sort = 'name';
        break;
      case 'expiring':
        filter['expires_on_lt'] = moment()
          .add(61, 'days')
          .startOf('day')
          .toISOString();
        filter['dead'] = false;
        sort = 'expires_on';
        break;
      case 'active':
        filter['expires_on_gteq'] = moment()
          .add(61, 'days')
          .startOf('day')
          .toISOString();
        filter['dead'] = false;
        sort = 'expires_on';
        break;
      case 'waitingInvoice':
        filter['expires_on_lt'] = moment()
          .add(61, 'days')
          .startOf('day')
          .toISOString();
        filter['dead'] = false;
        filter['waiting_invoice'] = true;
        sort = 'expires_on';
        break;
      case 'all':
        sort = 'name';
        break;
    }

    if (this.sortDirection !== 'asc') {
      sort = `-${sort}`;
    }

    return { filter, sort, include: include.join(',') };
  }
});
