import { all, reject, resolve } from 'rsvp';

import ItemsCartMixin from '../mixins/cart-items-manipulation';
import Logger from '../utils/logger';
import {
  SERVICE_TYPES,
  SERVICE_EXTRA_CART_ITEMS,
  GROUP_ONE_ALLOWED_TLDS
} from '../utils/service-config';
import Service from '@ember/service';
import { camelize } from '@ember/string';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

class InvalidItemsCombinationError extends Error {
  constructor(message, itemType, invalidItems) {
    super(message);
    this.itemType = itemType;
    this.invalidItems = invalidItems;
    this.name = 'InvalidItemsCombinationError';
  }
}

export default Service.extend(ItemsCartMixin, {
  currentUser: service(),
  gtmEvents: service(),
  session: service(),
  store: service(),
  config: service(),
  intl: service(),
  serverCart: service(),
  loading: false,
  itemsArray: null,
  coupon: null,
  _itemsChange() {
    return this.save();
  },
  _getItemFullName(type, params) {
    switch (type) {
      case SERVICE_TYPES.REGISTRATION:
        return `${params.name}.${params.tld}`;
      case SERVICE_TYPES.TRANSFER:
        return `${params.name}.${params.tld}`;
      case SERVICE_TYPES.SAFETY_GUARD:
        return `${params.name}.${params.tld}`;
      default:
        return params.name;
    }
  },
  getInvalidityItems(newItem) {
    let invalidItems = [];
    const items = this.items;

    // pokud nejsou polozky nevalidujeme
    if (!items.length) return true;

    const { itemsByDomain } = this;

    const domainItems =
      itemsByDomain[this._getItemFullName(newItem.type, newItem.params)];

    if (!domainItems) {
      return invalidItems;
    }
    const itemsByType = this._itemsByType(domainItems);
    switch (newItem.type) {
      case SERVICE_TYPES.YOLA:
        if (itemsByType.hosting) invalidItems.push(SERVICE_TYPES.HOSTING);
        if (itemsByType.sslCert) invalidItems.push(SERVICE_TYPES.SSL_CERT);
        if (itemsByType.safetyGuard)
          invalidItems.push(SERVICE_TYPES.SAFETY_GUARD);
        break;
      case SERVICE_TYPES.SAFETY_GUARD:
        if (itemsByType.yola) invalidItems.push(SERVICE_TYPES.YOLA);
        break;
      case SERVICE_TYPES.HOSTING:
        if (itemsByType.yola) invalidItems.push(SERVICE_TYPES.YOLA);
        if (itemsByType.email) invalidItems.push(SERVICE_TYPES.EMAIL);
        break;
      case SERVICE_TYPES.EMAIL:
        if (itemsByType.hosting) invalidItems.push(SERVICE_TYPES.HOSTING);
        break;
      case SERVICE_TYPES.SSL_CERT:
        if (itemsByType.yola) invalidItems.push(SERVICE_TYPES.YOLA);
        break;
    }

    return invalidItems;
  },
  getInvalidItemsToRemove(record) {
    let invalidItems = [];
    const items = this.items;

    // pokud nejsou polozky nevalidujeme
    if (!items.length) return true;

    const { itemsByDomain } = this;

    const domainItems =
      itemsByDomain[this._getItemFullName(record.type, record)];

    if (!domainItems) {
      return invalidItems;
    }

    const itemsByType = this._itemsByType(domainItems);

    switch (record.type) {
      case SERVICE_TYPES.REGISTRATION:
        if (itemsByType.yola) invalidItems.push(...itemsByType.yola);
        break;
      case SERVICE_TYPES.TRANSFER:
        if (itemsByType.yola) invalidItems.push(...itemsByType.yola);
        break;
    }
    return invalidItems;
  },
  items: computed('itemsArray.[]', function () {
    const itemObjects = this.get('itemsArray').map(item => {
      const itemObject = this._getRecordForId(item.id);
      return itemObject;
    });
    return itemObjects;
  }),
  _itemsByType(items) {
    if (!items || items.length === 0) return {};

    return items.reduce((out, item) => {
      if (out[item.type]) {
        out[item.type].pushObject(item);
      } else {
        out[item.type] = [item];
      }
      return out;
    }, {});
  },
  _itemsByDomain(items) {
    if (!items || items.length === 0) return {};

    return items.reduce((out, item) => {
      if (out[item.fullName]) {
        out[item.fullName].pushObject(item);
      } else {
        out[item.fullName] = [item];
      }
      return out;
    }, {});
  },
  itemsByType: computed('items.{type}', function () {
    const { items } = this;
    return this._itemsByType(items);
  }),
  itemsByDomain: computed('items.[]', function () {
    const { items } = this;
    // nemame items tak nic
    return this._itemsByDomain(items);
  }),
  groupOneWorkflow: computed('items.[]', function () {
    if (this.items.length === 0) return false;
    const isAllAllowedDomains = this.items.every(
      item =>
        GROUP_ONE_ALLOWED_TLDS.includes(item.get('tldName')) &&
        item.get('type') === SERVICE_TYPES.REGISTRATION
    );
    return isAllAllowedDomains;
  }),
  addItem(type, params, save = true) {
    Logger.log('[CART] adding', type, params, save);
    const invalidItems = this.getInvalidityItems({ type, params });
    if (invalidItems.length) {
      return reject(
        new InvalidItemsCombinationError(
          `item ${type} cannot be added`,
          type,
          invalidItems
        )
      );
    }
    const store = this.get('store');
    const objectKey = this._getKeyForObject(type, params);
    let item = store.peekAll(`${type}CartItem`).findBy('key', objectKey);
    const storedRecord = this.inCart(type, params);

    if (storedRecord) {
      Logger.log('[CART] adding - already exists', type, params);
      return resolve(storedRecord);
    } else {
      let parent;
      if (params.parentType && params.parentId) {
        parent = this.get('store').peekRecord(
          `${params.parentType}CartItem`,
          params.parentId
        );
      }
      if (!params.key) {
        params['key'] = objectKey;
      }

      if (!params.id) {
        params['id'] = Math.random().toString(36).substring(7);
      }

      params['type'] = type;

      params['source'] =
        (parent && parent.get('source')) ||
        params.source ||
        this.get('source') ||
        'domena';
      params['createdAt'] = new Date();
      Logger.log('[CART] adding', type, params);

      if (!item) {
        try {
          item = store.createRecord(`${type}CartItem`, params);
        } catch (error) {
          Logger.error('[CART] failed to restore', type, params, error);
        }
        if (!item) return resolve();
        item.setDefaults();
      }
      const id = item.get('id');

      this.gtmEvents.logCartAdd(type, item);

      this.get('itemsArray').addObject({
        type,
        id,
        key: objectKey,
        source: params['source']
      });
      item._didCreate();
      if (save) this._itemsChange();

      return resolve(item);
    }
  },
  removeItem(type, params, save = true) {
    Logger.log('[CART] removing', type, params, save);
    const objectKey = this._getKeyForObject(type, params);
    const item = this.get('itemsArray').findBy(
      params.id ? 'id' : 'key',
      params.id ? params.id : objectKey
    );

    if (!item) return;
    const record = this.get('store').peekRecord(`${type}CartItem`, item.id, {
      reload: true
    });
    Logger.log('[CART] removing', type, params);

    if (record) {
      record
        .get('_relatedObjects')
        .rejectBy('isDeleted')
        .forEach(relatedItem => {
          const relatedParams = relatedItem.getProperties('type', 'id', 'key');
          this.removeItem(relatedParams.type, relatedParams);
        });

      if (save) this.gtmEvents.logCartRemove(type, record);
      const itemsToRemove = this.getInvalidItemsToRemove(record);
      if (itemsToRemove.length) {
        if (save) alert(this.intl.t('yola_order.remove_invalid'));
        // remove invalid items
        itemsToRemove.forEach(item => {
          this.removeItem(item.type, { key: item.key, id: item.id }, true);
        });
      }

      record._didDelete();
      this.get('itemsArray').removeObject(item);
      if (save) this._itemsChange();
      return this.store.unloadRecord(record);
    } else {
      return false;
    }
  },
  addExtraServices() {
    Logger.log('[CART] adding extra services');
    this.items.forEach(item => {
      const extra_cart_items = this.get('currentUser.groupOneWorkflow')
        ? []
        : SERVICE_EXTRA_CART_ITEMS[item.type];
      (extra_cart_items || []).map(extraItemType => {
        Logger.log('[CART] adding extra service', extraItemType);
        const canByAdded = item.availableServices.findBy('type', extraItemType);
        if (!canByAdded) return;
        return this.addItem(extraItemType, {
          name: item.get('name'),
          tld: item.get('tld'),
          parentId: item.get('id'),
          parentType: item.get('type'),
          source: 'domena'
        });
      });
    });
  },
  clear() {
    Logger.log('[CART] cart reset');
    this.set('loading', false);
    let items = this.get('baseItems');
    return all(
      items.map(item => {
        return this.removeItem(
          item.get('type'),
          { key: item.get('key') },
          false
        );
      })
    );
  },
  init() {
    this._super(...arguments);
    this.set('itemsArray', []);
    // load dat je jako Promise v application route
  },
  save() {
    if (this.loading || !this.serverCart) {
      return;
    }
    Logger.log('[CART] saving cart', this.get('items.length'));

    this.set('loading', true);
    return this.serverCart.save().finally(() => {
      this.set('loading', false);
    });
  },
  setCoupon(couponCode) {
    this.set('coupon', couponCode);
    this.save();
  },
  async load(saved = false) {
    if (this.loading) {
      return;
    }

    Logger.log('[CART] loading cart, saved:', saved);

    this.set('loading', true);

    let serverCartItems = [];
    try {
      if (saved) {
        serverCartItems = await this.serverCart.loadSaved();
      } else {
        serverCartItems = await this.serverCart.load();
      }

      Logger.log(
        '[CART] loading cart, adding items serverCartItems',
        serverCartItems.map(item => `${item.id} - ${item.type}`)
      );

      (serverCartItems || []).forEach(serverCartItem => {
        const { type } = serverCartItem;
        if (!type) return;

        this.addItem(camelize(type), serverCartItem);
      });
    } catch (error) {
      Logger.error('[CART]', error);
    }

    this.set('loading', false);

    // save cart - link with existing, also if server cart empty
    if (serverCartItems.length === 0 && !saved && !this.groupOneWorkflow) {
      Logger.log('[CART] linking cart to user');
      this.save();
    }
  },
  reserServerCart() {
    if (this.loading) {
      return;
    }
    Logger.log('[CART] reseting cart');
    this.set('serverCart.content', { 'cart-items': null });
    return this.serverCart.save();
  },
  _convertKeyToClassName(key) {
    return key.split('_')[0];
  },
  _getRecordForId(id) {
    const lsItem = this.get('itemsArray').findBy('id', id);

    if (lsItem) {
      const item = this.get('store').peekRecord(
        `${lsItem.type}-cart-item`,
        lsItem.id
      );
      if (item) {
        return item;
      } else {
        Logger.error(
          '[CART]',
          `Item ${lsItem.type}-cart-item ${lsItem.id} missing`
        );
        return this.get('itemsArray').removeObject(lsItem);
      }
    }
    return reject();
  }
});
