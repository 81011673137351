import WyApiAdapter from './wy-api';
import { inject as service } from '@ember/service';

export default WyApiAdapter.extend({
  currentUser: service(),
  namespace: 'order',
  urlForCreateRecord() {
    if (this.get('currentUser.groupOneWorkflow')) {
      return `${this.groupOneHost}/v1/orders`;
    }
    return this._super(...arguments);
  },
  urlForFindRecord(id) {
    if (this.get('currentUser.groupOneWorkflow')) {
      return `${this.groupOneHost}/v1/orders/${id}`;
    }
    return this._super(...arguments);
  },
  pathForType() {
    return 'order';
  }
});
