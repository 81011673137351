import { reject as EmberReject, resolve as EmberResolve } from 'rsvp';
import { buildValidations, validator } from 'ember-cp-validations';

import Contact from './contact';
import DS from 'ember-data';
import NonRestActions from '../mixins/non-rest-action';
import { alias } from '@ember/object/computed';
import { camelize } from '@ember/string';
import { computed, observer } from '@ember/object';

export const LEGAL_FORMS = {
  SRO: 'SRO',
  FO: 'FO',
  AS: 'AS',
  OTHER: 'OTHER'
};

const validations = buildValidations({
  login: validator('length', {
    min: 1,
    disabled: computed(function () {
      return this.get('model.validatorDisabled');
    }).volatile()
  }),
  password: [
    validator('length', {
      min: 8,
      messageKey: 'validations.strong_password_a',
      disabled: computed(function () {
        return this.get('model.validatorDisabled');
      }).volatile()
    }),
    validator('format', {
      regex: /(?=.*[a-z])(?=.*[A-Z])/,
      messageKey: 'validations.strong_password_b',
      disabled: computed(function () {
        return this.get('model.validatorDisabled');
      }).volatile()
    }),
    validator('format', {
      regex: /(?=.*[0-9])|(?=.*[!@#$%^&*(){}[\]<>?/|-]+|.*[!@#$%^&*(){}[\]<>?/|-])/,
      messageKey: 'validations.strong_password_c',
      disabled: computed(function () {
        return this.get('model.validatorDisabled');
      }).volatile()
    }),
    validator('format', {
      regex: /^[^\s][^\s]+$/,
      messageKey: 'validations.spaces',
      disabled: computed(function () {
        return this.get('model.validatorDisabled');
      }).volatile()
    })
  ],
  passwordConfirm: validator('confirmation', {
    on: 'password',
    messageKey: 'validations.password_match'
  })
});

export default Contact.extend(validations, NonRestActions, {
  // profile: DS.belongsTo('profile'),
  priceList: DS.belongsTo('price-list'),
  // account: DS.belongsTo('account'),
  key: DS.attr('string'),
  // login: DS.attr('string'),
  mojeid: DS.attr('string'),
  password: DS.attr('string'),
  passwordConfirm: DS.attr('string'),
  authToken: DS.attr('string'),
  generatedPassword: DS.attr('string'),
  accountId: DS.attr('number'),
  deposit: alias('account.deposit'),
  priceListId: alias('account.priceList.id'),
  adminContactId: DS.attr('number'),
  toOrigAdminCheck: false,
  update_password(data) {
    data = {
      'old-password': data.oldPassword,
      'new-password': data.newPassword,
      'new-password-confirmation': data.newPasswordConfirmation
    };
    return this.nonRestAction(
      'password',
      'PUT',
      { data: { attributes: data } },
      { withoutId: true }
    ).then(
      data => {
        return EmberResolve(data);
      },
      error => {
        const errors = {};
        error.errors.forEach(e => {
          errors[
            e.source.pointer && camelize(e.source.pointer.split('/').pop())
          ] = { code: e.code };
        });
        return EmberReject(errors);
      }
    );
  },
  // WY-API attrs
  billfirm: DS.attr('string'),
  contactName: DS.attr('string'),
  contactEmail: DS.attr('string'),
  contactPhone: DS.attr('string'),
  contactMobile: DS.attr('string'),
  idCustomer: DS.attr('string'),
  invoiceCompanyid: DS.attr('string'),
  invoiceCurrency: DS.attr('string'),
  invoiceEmail: DS.attr('string'),
  invoiceLanguage: DS.attr('string'),
  invoiceName: DS.attr('string'),
  invoicePostcode: DS.attr('string'),
  invoiceState: DS.attr('string', { defaultValue: 'CZ' }),
  invoiceStreet: DS.attr('string'),
  invoiceTax: DS.attr('string'),
  invoiceTown: DS.attr('string'),
  invoiceVat: DS.attr('number'),
  legalForm: DS.attr('string', { defaultValue: LEGAL_FORMS.FO }),
  login: DS.attr(),
  postName: DS.attr('string'),
  postPostcode: DS.attr('string'),
  postPostponed: DS.attr('number'),
  postState: DS.attr('string'),
  postStreet: DS.attr('string'),
  postTown: DS.attr('string'),
  // WY-API aliases
  city: alias('invoiceTown'),
  company: computed('legalForm', {
    get() {
      return this.legalForm !== LEGAL_FORMS.FO;
    },
    set(key, value) {
      this.set('legalForm', value ? LEGAL_FORMS.SRO : LEGAL_FORMS.FO);
      return value;
    }
  }),
  companyName: alias('invoiceName'),
  currency: alias('invoiceCurrency'),
  dic: alias('invoiceTax'),
  email: alias('contactEmail'),
  ic: alias('invoiceCompanyid'),
  name: computed('contactName', 'invoiceName', {
    get() {
      return this.contactName || this.invoiceName;
    },
    set(key, value) {
      this.set('contactName', value);
      return value;
    }
  }),
  dialingCodeObserver: observer('dialingCode', function () {
    const contactPhone = this.get('contactPhone');
    if (contactPhone) {
      this.set(
        'contactPhone',
        [this.dialingCode, contactPhone.split('.').pop()].join('.')
      );
    }
  }),
  phone: computed('dialingCode', 'contactPhone', {
    get() {
      if (this.get('contactPhone')) {
        const phoneWithDial = this.get('contactPhone').split(',')[0] || '';
        const phoneParts = phoneWithDial.split('.');
        if (phoneParts.length > 1) {
          this.set('dialingCode', phoneParts[0]);
          return phoneParts[1];
        }
      }
    },
    set(key, value) {
      this.set('contactPhone', [this.get('dialingCode'), value].join('.'));
      return value;
    }
  }),
  street: alias('invoiceStreet'),
  zip: alias('invoicePostcode')
});
