import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default Route.extend({
  headTags: Object.freeze([
    {
      type: 'link',
      tagId: 'canonical-link',
      attrs: {
        rel: 'canonical',
        href: 'https://www.domena.cz/payment-fail'
      }
    }
  ]),
  ideas: service(),
  queryParams: {
    order_ids: {}
  },
  model() {
    return this.store.queryRecord('invoice-local', { key: 'current' }).then(
      record => {
        return record;
      },
      () => {
        return null;
      }
    );
  },
  afterModel() {
    this.get('ideas').clear(); // TODO-FIX lepsi volat na checkout?
  }
});
