import Route from '@ember/routing/route';
import Logger from '../utils/logger';
import { reject } from 'rsvp';
import { inject as service } from '@ember/service';

export default Route.extend({
  cart: service(),
  router: service(),
  checkout: service(),
  currentUser: service(),
  fastboot: service(),
  queryParams: {
    gopay: {},
    id: {},
    order_id: {},
    adyen_status: {},
    transactionId: {}
  },
  model(params) {
    Logger.log('[PAYMENT CHECK] model');

    if (this.get('fastboot.isFastBoot')) {
      return;
    }

    return this.store.findAll('checkout').then(checkoutModels => {
      const checkoutModel = checkoutModels.findBy('key', 'current');

      if (this.get('currentUser.groupOneWorkflow')) {
        Logger.log('[PAYMENT CHECK] group one workflow');
        if (['completed'].includes(params.adyen_status)) {
          Logger.log('[PAYMENT CHECK]  group one workflow completed');
          return this.replaceWith('thanks', {
            queryParams: {
              order_ids: params.order_id,
              adyen_status: params.adyen_status
            }
          });
        } else {
          Logger.log('[PAYMENT CHECK] group one workflow failed');
          return this.replaceWith('payment-fail', {
            queryParams: {
              order_ids: params.order_id,
              adyen_status: params.adyen_status,
              code: params.adyen_status
            }
          });
        }
      } else {
        const type = 'wy-order';
        const adapter = this.store.adapterFor(type);
        const url = `${adapter
          .buildURL(type)
          .replace('/order?', '/checkPay?')}&encode=0`;
        return adapter
          .ajax(url, 'POST', {
            data: {
              data: {
                gopay: params.gopay,
                id: params.transactionId || params.id
              },
              data2: [
                {
                  HTTP_REFERER:
                    window && window.location && window.location.href
                }
              ]
            }
          })
          .then(data => {
            if (!data.success) {
              return reject('Payment check failed');
            }
            if (['fail', 'pending'].includes(data.result)) {
              return this.replaceWith('payment-fail', {
                queryParams: { code: data.result }
              });
            } else {
              return this.replaceWith('thanks', {
                queryParams: {
                  invoice_id: checkoutModel.get('invoice.IDinvoice')
                }
              });
            }
          });
      }
    });
  }
});
