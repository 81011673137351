import SERVICE_CONFIG from '../utils/service-config';
import WyApiSerializer from './wy-api';
import config from '../config/environment';
import { dasherize } from '@ember/string';
import { inject as service } from '@ember/service';

export default WyApiSerializer.extend({
  currentUser: service(),
  adjustItemData(type, item) {
    switch (type) {
      case 'registration':
        item.attributes.period = item.attributes.period / 12;
        item.attributes.quantity = item.attributes.quantity / 12;
        break;

      case 'email':
        item.attributes.selectedUsers = item.attributes.COUNT;
        item.attributes.selectedCapacity = item.attributes.DISK;
        delete item.attributes.COUNT;
        delete item.attributes.DISK;
        break;
    }
    return item;
  },
  normalizeResponse(
    store,
    primaryModelClass,
    payload,
    ...rest /*, requestType*/
  ) {
    const serviceConfig = SERVICE_CONFIG[config.LOCALE];
    payload.data = { ...payload.data, ...payload.basket };
    const response = this._super(store, primaryModelClass, payload, ...rest);
    if (!response.data) {
      throw Error('No basket');
    }

    if (!response.data.id) {
      response.data.id =
        (payload.basket && payload.basket.hash) ||
        (response.basket && response.basket.hash);
    }

    const order =
      response.data &&
      response.data.attributes &&
      response.data.attributes.order;

    if (!order) {
      return response;
    }

    const orderData = JSON.parse(order);

    const { items } = orderData;

    response.data.relationships = {
      cartItems: {
        data: items.map(item => {
          const packConfig = serviceConfig[item.pack];
          const itemIdObject = item.params.findBy('name', 'id');
          return {
            id: itemIdObject && itemIdObject.value,
            type: `${dasherize(packConfig.cartItemType)}-cart-item`
          };
        })
      }
    };

    response.included = items.map(item => {
      const packConfig = serviceConfig[item.pack];
      const customParams = item.params.reduce((out, item) => {
        out[item.name] = item.value;
        return out;
      }, {});

      const itemIdObject = item.params.findBy('name', 'id');
      delete item.params;
      return this.adjustItemData(serviceConfig[item.pack].cartItemType, {
        id: itemIdObject && itemIdObject.value,
        type: `${packConfig.cartItemType}-cart-item`,
        attributes: {
          ...item,
          ...customParams,
          quantity: item.period,
          name: item.name.replace(`.${customParams.tld}`, ''),
          type: packConfig.cartItemType,
          basketInfo: item.basket_info
        }
      });
    });

    response.included.forEach(included => {
      delete included.attributes.id;
    });

    delete response.data.attributes.data;

    response.data.attributes.currency = this.get('currentUser.currency');

    return response;
  },
  serialize(record, options) {
    let json = this._super(record, options);

    delete json.created_at;
    delete json.form_name;
    delete json.i_dbasket;
    delete json.i_dcustomer;
    delete json.updated_at;

    json.order.items = json.order.items.map(item => ({
      ...item,
      id: `${item.type}-${item.name}`
    }));

    const { currency, lang } = json;
    delete json.currency;
    delete json.lang;

    const outJson = { data: json };
    outJson.currency = currency;
    outJson.lang = lang;

    return outJson;
  }
});
