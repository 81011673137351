import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default Controller.extend({
  currentUser: service(),
  modal: service(),
  router: service(),
  enabledItems: false,
  actions: {
    onSuccess() {
      if (this.get('currentUser.afterLoginAction') === 'domainTransfer') {
        // TODO transfer
        // this.router.transitionTo('after-login', { queryParams: { type: 'transfer' } });
      } else {
        this.router.transitionTo('home');
      }
    }
  }
});
