import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

export default Helper.extend({
  intl: service(),
  cms: service(),
  compute([text]) {
    const cmsValue = this.get(`cms._translationData.${text}`);
    if (typeof cmsValue === 'object') {
      return Object.keys(cmsValue).any(key => cmsValue[key] !== null);
    }
    return !(typeof cmsValue === 'undefined');
  }
});
