import { reject, resolve } from 'rsvp';

import JWT from 'ember-simple-auth-token/authenticators/jwt';
import ajax from '../utils/ajax';
import { assign } from '@ember/polyfills';
import { inject as service } from '@ember/service';

export default JWT.extend({
  currentUser: service(),
  session: service(),
  makeRefreshData() {
    return;
  },

  makeRequest(url, data, headers) {
    const { method = 'POST' } = headers || {};
    delete headers.method;

    return ajax(url, {
      method: method,
      headers: assign(
        {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        headers
      ),
      body: data && JSON.stringify(data)
    })
      .then(response => {
        return { json: response };
      })
      .catch(error => {
        return reject(error);
      });
  },

  refreshAccessToken() {},

  restore(orgData) {
    return this._super(...arguments).then(data => {
      const { token, exp } = data || {};
      if (token) {
        orgData = { ...orgData, token, exp };
        return resolve(orgData);
      } else {
        return reject();
      }
    });
  },

  authenticate({ token, customer: payload }) {
    if (!token) {
      return Promise.reject(Error('No credentials'));
    }

    const { customer } = payload || {};
    if (Array.isArray(customer.phone)) {
      customer.phone = `+${customer.phone.join('.')}`;
    } else if (typeof customer.phone === 'string') {
      customer.phone = `+${customer.phone}`;
    }

    payload['groupOne'] = true;
    payload['customer'] = customer;

    this.set('currentUser.groupOneUser', null);

    return Promise.resolve(
      this.handleAuthResponse({ ...payload, token } || {})
    );
  }
});
