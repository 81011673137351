import WyApiSerializer from './wy-api';
import { inject as service } from '@ember/service';
import { underscore } from '@ember/string';

export default WyApiSerializer.extend({
  currentUser: service(),
  normalizeResponse(store, primaryModelClass, payload /* id , requestType*/) {
    if (this.currentUser.groupOneWorkflow) {
      const { order } = payload;
      const { payment, order_lines } = order;
      const order_line = order_lines[0];
      return {
        data: {
          attributes: {
            paymentLink: payment.url,
            unitPrice: payment.total_amount,
            itemText: order_line.name,
            basketHash: order.wg_basket_id
          },
          type: primaryModelClass.modelName,
          id: order.id
        }
      };
    }
    return {
      data: {
        attributes: {
          paymentLink: payload.payment_link,
          idsObjects: payload.ids_objects,
          invoice: payload.invoice
        },
        type: primaryModelClass.modelName,
        id: payload.IDinvoice
      }
    };
  },
  keyForAttribute(attr) {
    return underscore(attr);
  },
  serialize(snapshot, options) {
    if (this.currentUser.groupOneWorkflow) {
      const json = this._super(snapshot, options);

      return {
        order: {
          payment: {
            return_url: json.rurl
          },
          original_order_id: json.order?.id,
          wg_basket_id: json.basket_hash
        }
      };
    }
    return this._super(snapshot, options);
  }
});
