import Component from '@ember/component';
import Logger from '../utils/logger';
import config from '../config/environment';
import { inject as service } from '@ember/service';
import { tryInvoke } from '@ember/utils';
import { trySet } from '@ember/object';

export default Component.extend({
  user: null,
  gtmEvents: service(),
  store: service(),
  session: service(),
  didInsertElement() {
    let data = {
      invoiceLanguage: config.LOCALE
    };

    if (config.environment === 'development') {
      data = {
        ...data,
        email: 'test@test.com',
        name: 'Tonda Tester',
        phone: '123456789',
        street: 'Testovací 123',
        city: 'Praha',
        zip: '12345'
      };
    }
    const newUser = this.store.createRecord('user', data);

    this.set('user', newUser);
    this.set('currentUser.groupOneUser', newUser);
  },
  willDestroyElement() {
    const user = this.get('user');
    if (user.isNew) {
      user.rollbackAttributes();
    }
  },
  actions: {
    userSubmit() {
      this.set('loading', true);
      this.set('user.validatorDisabled', true);
      this.set('user.login', this.get('user.email'));
      this.set('user.password', undefined);

      if (this.get('user.validations.isValid')) {
        this.get('user')
          .save()
          .then(
            result => {
              const authToken = result.get('authToken');
              if (!authToken) {
                let login = result.get('login');
                let password = result.get('password');
                let credentials = { identification: login, password };
                this.gtmEvents.logSignUp(result);

                this.get('session')
                  .authenticate('authenticator:wy-api', credentials)
                  .then(
                    () => {
                      result.unloadRecord();
                      tryInvoke(this, 'authenticationSuccess');
                    },
                    error => {
                      this.set('loading', false);
                      tryInvoke(this, 'authenticationFailed', error);
                    }
                  );
              } else {
                this.get('session')
                  .authenticate('authenticator:group-one-api', {
                    token: authToken,
                    customer: result.serialize()
                  })
                  .then(
                    () => {
                      result.unloadRecord();
                      tryInvoke(this, 'authenticationSuccess');
                    },
                    error => {
                      this.set('loading', false);
                      tryInvoke(this, 'authenticationFailed', error);
                    }
                  );
              }
            },
            error => {
              this.set('loading', false);
              trySet(this, 'showErrors', true);
              Logger.error(error);
            }
          );
      } else {
        this.set('loading', false);
        trySet(this, 'showErrors', true);
      }
    }
  }
});
